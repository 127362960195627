<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <free-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="false"
      @on-submit="createOrderLabel"
    >
    </free-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import LabelService from '@admin/services/LabelService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import FreeForm from './_From'

export default {
  name: 'LabelOrderCreate',
  components: { PlaceholderForm, FreeForm },
  data () {
    return {
      pageTitle: '创建标签',
      model: {
        name: '',
        parent_id: '1',
        type: 0,
        classify: [],
        classify_id: ''
      }
    }
  },
  async created () {
    this.model.type = this.$router.currentRoute.query.type
    if (this.model.type === '0') {
      this.pageTitle = '创建标签'
    } else {
      this.model.parent_id = '0'
      this.pageTitle = '创建分类'
    }
    const { data } = await flatry(LabelService.orderCreate())
    if (data) {
      this.model.classify = data.data.classify
    }
  },
  methods: {
    async createOrderLabel (model, success, callback) {
      const { data } = await flatry(LabelService.orderCreate(model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/label/order/update',
          query: { id: data.data, type: this.type }
        })

        success()
      }

      callback()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
