<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="140px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >

    <el-form-item
      label="分类"
      v-if="formModel.parent_id != 0"
    >
      <el-select
        v-model="formModel.classify_id"
        placeholder="请选择"
      >
        <el-option
          v-for="item in formModel.classify"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="名称">
      <el-col :span="9">
        <el-input
          v-model="formModel.name"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'LabelOrderFrom',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    },
    type: {}
  },
  data () {
    return {
      submitLoading: false,
      formModel: null,
      formRules: null
    }
  },

  async created () {
    if (this.isEdit) {
      this.formModel = Object.assign({}, this.model)
    } else {
      this.formModel = Object.assign(this.model)
    }
  },

  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
